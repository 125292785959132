import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
export default function Copyright() {
  const { locale } = React.useContext(LocaleContext);
  return (
    <React.Fragment>
      <Typography variant="body2" color="textSecondary" align="center">
        <Link href="#">{Languages[locale].global.terms}</Link>
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="/reckon.ai">
          {process.env.main || "Reckon.ai"}
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </React.Fragment>
  );
}
