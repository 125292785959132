import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  header: {
    position: "relative",
    overflow: "hidden",
    margin: "20px 0",
    willChange: "transform, opacity",
  },
}));
const Address = ({ data }) => (
  <address className="header__address">{data.subtitle}</address>
);
const HeaderTitle = ({ data }) => (
  <h1 className="header__title">{data.title}</h1>
);

const HeaderTop = ({ data }) => (
  <div className="header__top">
    <HeaderTitle data={data} />
  </div>
);
const HeaderItem = ({ data }) => {
  return (
    <div className="header__item">
      <HeaderTop data={data} />
      <Address data={data} />
    </div>
  );
};
const Header = ({ ...props }) => {
  const classes = useStyles();
  return (
    <div className={[clsx(classes.header), "container"].join(" ")}>
      {props.children}
    </div>
  );
};

export default {
  HeaderItem,
  HeaderTop,
  HeaderTitle,
  Address,
  Header,
};
