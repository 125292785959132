import React from "react";
import AppLayout from "./layout";
import ReactCodeInput from "react-code-input";
import { makeStyles } from "@material-ui/core/styles";
import Container from "../components/container";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import BuyByeService from "../services/buybye-service";
import ErrorMessage from "../components/errorMessage";
import clsx from "clsx";
import Loader from "./loader";
//const DNS = process.env.DNS || "app.reckon.ai";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import StripeService from "../services/stripe-service";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  codeContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  header: {
    position: "relative",
    
    //overflow: "hidden",
    marginTop: "20px",
    willChange: "transform, opacity",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    color: "white",
    width: "95%",
  },
  btn: {
    position: "absolute",
    width: "100%",
    top: "76%",
    color: "white",
  },
}));
const Address = ({ data }) => (
  <div>
    <address className="header__address">
      {data.subtitle}
    </address>
  </div>
);
const HeaderTitle = ({ data }) => (
  <h1 className="header__title" style={{ color: "#2e3079" }}>
    {data.title}
  </h1>
);

const HeaderTop = ({ data }) => (
  <div className="header__top">
    <HeaderTitle data={data} />
  </div>
);
const HeaderItem = ({ data }) => {
  return (
    <Container className="header__item" style={{}}>
      <HeaderTop data={data} />
      <Address data={data} />
    </Container>
  );
};
const OpenBuyBye = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [value, setValue] = React.useState(undefined);
  const { locale } = React.useContext(LocaleContext);
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState();
  const [_error, set_Error] = React.useState("");
  //const [currentUser, setCurrentUser] = useState(undefined);

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    let res = await BuyByeService.customerPayDebtStep1();
    if (res.success) {
      let _sRes = await StripeService.stripeConfirm(
        res.result.clientSecret,
        res.result.paymentMethod
      );
      console.log(_sRes);

      let _res2 = await BuyByeService.customerPayDebt({...res.result});

      if (_res2.success && _res2.result.debts.length <= 0) {
        handleClose();
        set_Error(true);
        let msg = { pt: "Pago com sucesso.", en: "Payment Successful." };
        setError(msg[locale]);
      } else {
        if (_res2.result.debts.length === values.debts) {
          let _error = {
            pt: "Erro ao processar o pagamento. Tente trocar o cartão.",
            en: `Can't process your payment.Try another card.`,
          };
  
          set_Error(_error[locale]);
        } else {
          let _error = {
            pt: `Erro ao processar o pagamento apenas conseguimos processar ${_res2.result.payment.debts.length} de ${values.debts} débitos. Tente trocar o cartão.`,
            en: `We can only process ${_res2.result.payment.debts.length} of ${values.debts} debts .Try another card.`,
          };
  
          set_Error(_error[locale]);
        }
      }

    }else{

      let _error = {
        pt: "Erro ao processar o pagamento. Tente trocar o cartão.",
        en: `Can't process your payment. Try another card.`,
      };

      set_Error(_error[locale]);
    }
    
    
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const sendOpen = async (value) => {
    if (value) {
      setLoader(true);
      setValue(value);

        let _res2 = await BuyByeService.openBuyByeToShop({
          machine: value,
        });

        console.log(_res2)
        if (_res2.success) {
          history.push("/in-shop");
        } else {
          let _error;
          if (_res2.errorMessage && _res2.errorMessage.message) {
            if (_res2.errorMessage.message.toLowerCase() == "error")
              _error = `Something went wrong. Try again later`;
            else _error = _res2.errorMessage.message;
          }
          setError(_error);
          setLoader(false);
        }
      }
    }; 

  const onClick = () => {
    sendOpen(value);
  };

  const codeStyle = {
    inputStyle: {
      fontFamily:
        "avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto,noto, segoe ui, arial, sans-serif",
      margin: "5px",
      MozAppearance: "textfield",
      width: "50px",
      borderRadius: "6px",
      fontSize: "1.5em",
      height: "50px",
      textAlign: "center",
      color: "black",
      border: "1px solid lightgray",
      borderRadius: "30px",
    },
    inputStyleInvalid: {
      fontFamily:
        "avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto,noto, segoe ui, arial, sans-serif",
      margin: "5px",
      MozAppearance: "textfield",
      width: "50px",
      borderRadius: "6px",
      textAlign: "center",
      fontSize: "1.5em",
      height: "50px",
      color: "black",
      border: "1px solid lightgray",
    },
    onChange: async (a) => {
      if (a.length === 4) {
        sendOpen(a);
      }
    },
  };

  if (loader) {
    return (
      <AppLayout>
        <Loader></Loader>
      </AppLayout>
    );
  }

  return (
    <AppLayout logo={true} bottomNav>
      <div className={clsx(classes.header)}>
        <HeaderItem
          data={{
            title: Languages[locale].openBuyByeTitle,
            subtitle: "Use the code to open the cabinet.",
          }}
        ></HeaderItem>
      </div>
      <ErrorMessage
        errorMessage={{ message: error }}
        height={"1.5em"}
        style={{}}
      ></ErrorMessage>

      <div className={clsx(classes.codeContainer)}>
        <ReactCodeInput type="number" fields={4} {...codeStyle} />
      </div>
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={process.env.PUBLIC_URL + "/gif-a.gif"}
          alt="Open Cabinet"
          style={{ width: "100%", height: "100%" }}
        />
      </Container>
      <Container className={clsx(classes.btn)}>
        <Button
          variant="contained"
          color="primary"
          className={clsx(classes.margin, classes.textField)}
          onClick={onClick}
          style={{
            borderRadius: "50px",
            padding: "12px 12px",
            opacity: "0.7",
          }}
        >
          {Languages[locale].openBuyBye}
        </Button>
      </Container>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {Languages[locale].debt.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {Languages[locale].debt.body(values)}
            <p style={{ color: "red" }}>{_error}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {Languages[locale].debt.cancel}
          </Button>
          <Button onClick={handleSubmit} type="submit" value="Submit">
            {Languages[locale].debt.continue}
          </Button>
        </DialogActions>
      </Dialog>
    </AppLayout>
  );
};
export default OpenBuyBye;
