import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
export default function SignIn(props) {
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  const _doc = props.match.params.id;
  const onClose = () => {
    history.goBack();
    return;
  };

  return (
    <AppLayout
      title={`${Languages[locale].global[_doc]}`}
      onClick={onClose}
    ></AppLayout>
  );
}
