import React from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import AuthService from "../services/auth-service";
import BuyByeService from "../services/buybye-service";
import { useHistory } from "react-router-dom";
import ErrorMessage from "./errorMessage";
import queryString from "query-string";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "95%",
  },
  textArea: {
    resize: "vertical",
    outline: "none",
    transition: "background-size 0.2s ease-in-out 0s",
    backgroundImage:
      "linear-gradient(rgb(137, 26, 28), rgb(137, 26, 28)), linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12))",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom, center calc(100% - 0.0625rem)",
    backgroundSize: "0px 0.125rem, 100% 0.0625rem",
    borderColor: "transparent",
    borderRadius: "0px",
    paddingRight: "0px",
    paddingLeft: "0px",
    overflow: "hidden",
    marginTop: "0.5em",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
}));

function FormControlLabelPlacement({ ...props }) {
  return (
    <FormControl className={props.className} component="fieldset">
      <FormLabel component="legend">
        {Languages[props.locale].helpForm.contactMethod.label}
      </FormLabel>
      <RadioGroup
        row
        aria-label="position"
        name="mobile"
        defaultValue="phone"
        style={{ justifyContent: "space-evenly", margin: "1em" }}
      >
        <FormControlLabel
          value="phone"
          onClick={props.onClick}
          control={<Radio color="primary" />}
          label={Languages[props.locale].helpForm.contactMethod.phone}
        />
        <FormControlLabel
          value="email"
          onClick={props.onClick}
          control={<Radio color="primary" />}
          label={Languages[props.locale].helpForm.contactMethod.email}
        />
      </RadioGroup>
    </FormControl>
  );
}
function MinHeightTextarea({ min = 5, ...props }) {
  const classes = useStyles();
  return (
    <FormControl
      className={props.className}
      component="fieldset"
      error={props.error || false}
      required
    >
      <FormLabel component="legend">
        {Languages[props.locale].helpForm.description.label}
      </FormLabel>
      <TextareaAutosize
        className={clsx(classes.textArea)}
        aria-label="minimum height"
        rowsMin={min}
        onChange={props.onChange("message")}
        placeholder={Languages[props.locale].helpForm.description.placeholder}
      />
    </FormControl>
  );
}

export default function HelpForm(props) {
  const classes = useStyles();
  const history = useHistory();
  const parsed = queryString.parse(history.location.search);
  const [error, setError] = React.useState("");
  const [values, setValues] = React.useState({
    id: parsed.sessUUID || "",
    phone: "",
    email: "",
  });
  const { locale } = React.useContext(LocaleContext);

  React.useEffect(() => {
    let user = AuthService.getCurrentUser();

    if (user) {
      if (user.user) user = user.user;
      setValues({ ...values, email: user.email, phone: user.phone });
    } else {
      history.push("/signin");
    }
  }, [history]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onClick = async () => {
    values.mentionId = parsed._ref ? parsed._ref : values.mentionId;
    const { subject, mentionId, message, email, phone, contact } = values;
    console.log({
      subject,
      mentionId,
      message,
      user: { email, phone, contact: contact || "phone" },
    });
    let res = await BuyByeService.createCase({
      subject,
      mentionId,
      message,
      user: { email, phone, contact: contact || "phone" },
    });
    if (res.success) {
      console.log(res);
      history.push("/");
      return;
    } else {
      setError(Languages[locale].error.requiredFields);
    }
  };

  return (
    <div className={"margin"}>
      <ErrorMessage errorMessage={{ message: error }}></ErrorMessage>
      {props.title && (
        <div className={clsx()}>
          <h1 className={"header__address"}>
            {Languages[locale].helpForm.title}
          </h1>
        </div>
      )}
      <div>
        <TextField
          label={Languages[locale].helpForm.mentionId}
          value={values.id || values.mentionId}
          disabled={values.id ? true : false}
          onChange={handleChange("mentionId")}
          id="standard-start-adornment"
          className={clsx(classes.margin, classes.textField)}
        />
        <TextField
          label={Languages[locale].helpForm.subject}
          required
          error=""
          helperText=""
          value={values.subject || ""}
          onChange={handleChange("subject")}
          id="standard-start-adornment"
          className={clsx(classes.margin, classes.textField)}
        />
        <MinHeightTextarea
          label=""
          value={values.message || ""}
          locale={locale}
          onChange={handleChange}
          id="standard-start-adornment"
          className={clsx(classes.margin, classes.textField)}
        />
        <FormControlLabelPlacement
          className={clsx(classes.margin, classes.textField)}
          onClick={handleChange("contact")}
          locale={locale}
        ></FormControlLabelPlacement>
        <TextField
          label={Languages[locale].helpForm.phone}
          disabled
          value={values.phone || ""}
          onChange={handleChange("phone")}
          id="standard-start-adornment"
          className={clsx(classes.margin, classes.textField)}
        />
        <TextField
          label={Languages[locale].helpForm.email}
          value={values.email || ""}
          onChange={handleChange("email")}
          id="standard-start-adornment"
          className={clsx(classes.margin, classes.textField)}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        className={clsx(classes.margin, classes.textField)}
        style={{ color: "white" }}
        onClick={onClick}
      >
        {Languages[locale].action.sendMessage}
      </Button>
    </div>
  );
}
