import React from "react";

import Container from "@material-ui/core/Container";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import header from "../components/header";
import HelpForm from "../components/helpForm";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
export default function SignIn() {
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);

  const onClose = () => {
    history.goBack();
    return;
  };
  return (
    <AppLayout
      title={Languages[locale].caseManagement.title}
      onClick={onClose}
      style={{ overflowY: "scroll" }}
    >
      <header.Header>
        <header.HeaderItem
          data={{
            title: Languages[locale].caseManagement.subtitle.title,
            subtitle: Languages[locale].caseManagement.subtitle.subtitle,
          }}
        ></header.HeaderItem>
      </header.Header>
      <Container>
        <HelpForm></HelpForm>
      </Container>
    </AppLayout>
  );
}
