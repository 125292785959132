import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import queryString from "query-string";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AppLayout from "./layout";
import Copyright from "../components/copyright";
import { useHistory } from "react-router-dom";
import ReactCodeInput from "react-code-input";
import Container from "../components/container";
import AuthService from "../services/auth-service";
import BackButton from "../components/backButton";
import logo from "../FoodSVG.svg";
import useStyles from "../css/signup";
import ErrorMessage from "../components/errorMessage";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import Link from "@material-ui/core/Link";

export default function SignIn() {
  const classes = useStyles();

  const history = useHistory();
  const [error, setError] = React.useState(undefined);
  const [expired, setExpired] = React.useState(undefined);
  const parsed = queryString.parse(history.location.search);
  const { locale } = React.useContext(LocaleContext);
  const codeStyle = {
    inputStyle: {
      fontFamily:
        "avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto,noto, segoe ui, arial, sans-serif",
      margin: "5px",
      MozAppearance: "textfield",
      width: "50px",
      borderRadius: "6px",
      fontSize: "1.5em",
      height: "50px",
      textAlign: "center",
      color: "black",
      border: "1px solid lightgray",
      borderRadius:"30px"
    },
    inputStyleInvalid: {
      fontFamily:
        "avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto,noto, segoe ui, arial, sans-serif",
      margin: "5px",
      MozAppearance: "textfield",
      width: "50px",
      borderRadius: "6px",
      textAlign: "center",
      fontSize: "1.5em",
      height: "50px",
      color: "black",
      border: "1px solid lightgray",
    },
    onChange: async (a) => {
      if (a.length === 6) {
        const user = AuthService.getPrivateUser();
        let res;
        if (parsed.forgot) {
          res = await AuthService.validateGenericCode({ code: a });
        } else {
          res = await AuthService.validateUser({ code: a, ...user });
        }
        if (res.data.success && !parsed.forgot) history.push("/home");
        else if (res.data.success && parsed.forgot)
          history.push("/new/password");
        else {
          setError(res.data.errorMessage.message);
          setExpired(res.data.errorMessage.expired);
          if (res.data.errorMessage.expired) {
            if (parsed.forgot) {
              await AuthService.getGenericCode();
            } else {
              await AuthService.newValidationCode();
            }
          }
        }
      }
    },
  };

  return (
    <AppLayout>
      <BackButton history={history} location={"/signin/"} />
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} style={{width:"15em"}} alt="avatar" />
          <ErrorMessage
            errorMessage={{ message: error }}
            height={"1.5em"}
          ></ErrorMessage>
          <Typography style={{margin:"20px 0px",color:"#354636",fontSize:"14px"}}>Digite o código de validação que recebeu</Typography>
          <ReactCodeInput type="number" fields={6} {...codeStyle} />
        </div>
        <Box mt={40}>
        <p
            style={{
              textAlign: "center",
              lineHeight: "14px",
              fontSize: "10px",
              fontWeight: "normal",
              fontStyle: "normal",
              marginTop: "10vh",
            }}
          >
            {"Copyright © "}
            <Link
              color="inherit"
              href="/reckon.ai"
              style={{ color: "#141A30" }}
            >
              {process.env.main || "Reckon.ai"}
            </Link>{" "}
            {new Date().getFullYear()}
            {""}
          </p>
        </Box>
      </Container>
    </AppLayout>
  );
}
