import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import { createBrowserHistory } from "history";
import Events from "./components/events";
import Profile from "./pages/profile";
import OpenBuyBye from "./pages/openBuyBye";
import Reciepts from "./pages/reciepts";
import SingleReciept from "./pages/singleReciept";
import CardForm from "./pages/creditCardForm";
import CaseManagement from "./pages/caseManagement";
import Login from "./pages/login";
import Logout from "./pages/logout";

import SignUp from "./pages/signup";
import Verify from "./pages/verify";
import Loader from "./pages/loader";
import ForgotPassword from "./pages/forgotPassword";
import NewPassword from "./pages/newPassword";
import Blank from "./pages/blank";
import inShop from "./pages/inShop";
import Success from "./pages/success";
import CloseDoor from "./pages/closeDoor";

import SingleCase from "./pages/singleCase";
import Document from "./pages/docs";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import Wrapper from "./pages/Wrapper";
import AuthService from "./services/auth-service";

function PrivateRoute({ component: Component, ...rest }) {
  const user = AuthService.getCurrentUser();
  let _render;
  if (user && user._id) {
    _render = (props) => {
      return <Component {...props} />;
    };
  } else {
    _render = (props) => {
      return <Redirect to="/signin" />;
    };
  }
  return <Route {...rest} render={(props) => _render(props)} />;
}
var hist = createBrowserHistory();
ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />

    <Router history={hist}>
      <App>
        <Wrapper>
          <Switch>
            <PrivateRoute path="/case-management/:id" component={SingleCase} />
            <PrivateRoute path="/case-management" component={CaseManagement} />
            <PrivateRoute path="/document/:id" component={Document} />
            <PrivateRoute path="/card/form" component={CardForm} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/home" component={OpenBuyBye} />
            <PrivateRoute path="/cabinet" component={OpenBuyBye} />
            <PrivateRoute path="/receipts/:id" component={SingleReciept} />
            <PrivateRoute path="/receipts" component={Reciepts} />
            <Route path="/signin" component={Login} />
{/*             <Route path="/forgot/password" component={ForgotPassword} /> */}
            <Route path="/new/password" component={NewPassword} />
{/*             <Route path="/signup" component={SignUp} /> */}
            <Route path="/verify" component={Verify} />
            <Route path="/loader" component={Loader} />
            <PrivateRoute path="/logout" component={Logout} />
            <PrivateRoute path="/blank" component={Blank} />
            <PrivateRoute path="/in-shop" component={inShop} />
            <PrivateRoute path="/success" component={Success} />
            <PrivateRoute path="/closeDoor" component={CloseDoor} />
            <PrivateRoute path="/" component={OpenBuyBye} />
          </Switch>
        </Wrapper>
      </App>
    </Router>
  </ThemeProvider>,
  document.querySelector("#root")
);
serviceWorker.unregister();
