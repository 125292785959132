import { loadStripe } from "@stripe/stripe-js";
import Config from "../config/config";


const stripeKEY = Config.stripeKEY || "pk_test_51H1whMCHfsc2g4jF5Lv6oIsyTbRUGylGnAI9vFd0KQ5EbF1PrjBzAhgdgxVVEBR2xo2HvK0VzTZn56cSniDfPHZ3006By7U7qG";
let stripePromise;
const _loadStripe = async () =>{
    stripePromise = await loadStripe(stripeKEY);
}

_loadStripe();

const stripeConfirm = async (pi,pm) =>{
    const result = await stripePromise.confirmCardPayment(
        pi,
        {
          payment_method: pm
        }
      );
      return result
}
export default {
    stripeConfirm
};