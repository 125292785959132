import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";

export default function BackButton({
  children,
  className,
  history,
  location,
  ...rest
}) {
  const onClose = () => {
    if (history) {
      history.push(location);
    }
  };

  return (
    <Button
      className="nav-bar__back"
      onClick={onClose}
      style={{
        color: "#141A30",
        position: "absolute",
        zIndex: "100000",
        margin: "3.5%",
        ...rest.style,
      }}
    >
      <ArrowBackIcon style={{ fontSize: "1.3em", marginRight: "5px" }} />
    </Button>
  );
}
