import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import BuyByeService from "../services/buybye-service";
import moment from "moment";
import Loader from "./loader";
import Avatar from "@material-ui/core/Avatar";
import header from "../components/header";
import Container from "../components/container";
import { Grid, Typography } from "@material-ui/core";
import { Done as DoneIcon, Kitchen as KitchenIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import AuthService from "../services/auth-service";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    padding: 10,
  },
  messageHistory: {
    color: "white",
    padding: "18px 20px",
    lineHeight: "26px",
    marginTop: "5px",
    fontSize: "16px",
    borderRadius: "7px",
    marginBottom: "15px",
    position: "relative",
    "&:after": {
      bottom: "100%",
      left: "13%",
      border: "solid transparent",
      content: "' '",
      height: "0",
      width: "0",
      position: "absolute",
      pointerEvents: "none",
      borderBottomColor: theme.palette.myMessage.main,
      borderWidth: "10px",
      marginLeft: "-10px",
    },
  },
  myMessageHistory: {
    background: theme.palette.myMessage.main,
    marginLeft: "30px",
  },
  otherMessageHistory: {
    background: theme.palette.otherMessage.main,
    marginRight: "35px",
    "&:after": {
      borderBottomColor: theme.palette.otherMessage.main,
      left: "88%",
    },
  },
  divider: {
    height: 28,
    margin: 4,
  },
  messageContainer: {
    display: "flex",
  },
  alignRight: {
    textAlign: "right",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  avatar: {
    fontSize: "1em",
    width: "30px",
    height: "30px",
    marginLeft: "5px",
    marginRight: "5px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
  sender: {
    marginTop: "20px",
  },
  messageLabel: {
    display: "inline-flex",
    textTransform: "capitalize",
  },
}));

const Message = ({ ...props }) => {
  const classes = props.classes;
  let letters = "";
  if (props.user.username)
    props.user.username.split(" ").forEach((name) => {
      letters = letters + name.charAt(0);
    });
  if (props.other) {
    return (
      <div className={[classes.messageContainer, classes.alignRight].join(" ")}>
        <div className={[classes.sender].join(" ")}>
          <span className={classes.messageLabel}>
            {"Customer Support "} {moment(props.data.createdAt).format("LT")}
            <Avatar
              style={{ background: "white" }}
              className={classes.avatar}
              alt={"cu"}
              src={"https://backoffice-dev.reckon.ai/plugins/images/user.png"}
            ></Avatar>
          </span>
          <div
            className={[
              classes.messageHistory,
              classes.otherMessageHistory,
            ].join(" ")}
          >
            {props.data.message}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={[classes.messageContainer].join(" ")}>
        <div className={classes.sender}>
          <span className={classes.messageLabel}>
            <Avatar className={classes.avatar}>{letters.toUpperCase()}</Avatar>
            {props.user.username} {moment(props.data.createdAt).format("LT")}
          </span>
          <div
            className={[classes.messageHistory, classes.myMessageHistory].join(
              " "
            )}
          >
            {props.data.message}
          </div>
        </div>
      </div>
    );
  }
};

export default function SignIn(props) {
  const history = useHistory();
  const classes = useStyles();
  const myRef = React.useRef(null);
  const [user, setUser] = React.useState(null);
  const [newMessage, setNewMessage] = React.useState(null);
  const [messages, setMessages] = React.useState([]);
  const [_case, setCase] = React.useState(null);
  const { locale } = React.useContext(LocaleContext);
  const onClose = () => {
    history.goBack();
    return;
  };
  const executeScroll = () => myRef.current.scrollIntoView();

  React.useEffect(() => {
    let user = AuthService.getCurrentUser();
    if (user) {
      if (user.user) user = user.user;
      setUser(user);
    }
    async function main() {
      let res = await BuyByeService.getCase(props.match.params.id);
      if (res.success) {
        setCase(res.result);
        setMessages(res.result.message);
        executeScroll();
      } else {
        onClose();
      }
    }
    main();
  }, [history]);

  if (!_case) {
    return (
      <AppLayout>
        <Loader></Loader>
      </AppLayout>
    );
  }

  const onChange = (e) => {
    setNewMessage(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(e);

    let res = await BuyByeService.updateCase({
      caseManagement: { _id: props.match.params.id },
      message: newMessage,
    });

    if (res.success) {
      setMessages([
        ...messages,
        {
          _id: Math.random(),
          createAt: Date.now(),
          message: newMessage,
          user: { ClientID: user._id },
        },
      ]);
      setNewMessage("");
      executeScroll();
    } else {
      console.log(res);
    }
  };
  return (
    <AppLayout
      title={Languages[locale].caseManagement.title}
      onClick={onClose}
      style={{ overflowY: "scroll" }}
    >
      <div>
        <header.Header>
          <header.HeaderItem
            data={{
              title: `${Languages[locale].casesListItensCase} ${_case.serial}`,
              subtitle: `${
                Languages[locale].casesListItensCreatedAt
              } - ${moment(_case.createdAt).format("DD/MM/YYYY")}`,
            }}
          ></header.HeaderItem>
        </header.Header>
      </div>
      <Container>
        <Grid container>
          <Grid item xs={5}>
            <Typography color="primary">
              <strong>
                <KitchenIcon style={{ fontSize: "inherit" }} />{" "}
                {`${Languages[locale].casesListItensMachine} - ${_case.machine.code}`}
              </strong>
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography color="primary">
              <strong>
                <DoneIcon style={{ fontSize: "inherit" }} />{" "}
                {`${Languages[locale].casesListItensStatus} - ${
                  Languages[locale].casesListItensStatusTrad[_case.status]
                }`}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <div style={{ marginTop: "1em" }}>
          <span>
            <strong>{Languages[locale].helpForm.subject} :</strong>
          </span>
          {` ${_case.subject}`}
        </div>
      </Container>
      <Container
        style={{ minHeight: "65vh", maxHeight: "65vh", overflow: "scroll" }}
      >
        {messages &&
          messages.length > 0 &&
          messages.map((m) => (
            <Message
              key={m._id}
              classes={classes}
              data={m}
              user={user}
              other={m.user.ClientID != user._id}
            />
          ))}
        <div ref={myRef}></div>
      </Container>
      <Paper
        component="form"
        className={classes.root}
        onSubmit={onSubmit}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          minHeight: "12vh",
        }}
      >
        <InputBase
          className={classes.input}
          placeholder={Languages[locale].helpForm.description.placeholder}
          value={newMessage || ""}
          style={{ width: "80%" }}
          onChange={onChange}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SendIcon />
        </IconButton>
      </Paper>
    </AppLayout>
  );
}
