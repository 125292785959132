export default {
  //BOTTOM NAV
  home: "Home",
  buybye: "BuyBye",
  account: "Account",
  purchaseList: "Purchase List",
  //HOME
  openBuyBye: "Open Fridge",
  // CABINET
  openBuyByeTitle: "Welcome to Noweat & Go",
  openBuyByeSubtitle: "Insert Cabinet code to shop",
  // receipts
  purchaseLabel: "Purchase",
  receiptSubtitle: (value) => {
    return `You Purchased ${value} items.`;
  },
  receiptDuration: (value) => {
    return `Your Purchase Time Was <strong>${value}s.</strong>`;
  },
  // close door page
  closeDoor: (expired) => {
    return `Thank you for your purchase.<p>Your payment is being processed.</p><p>${expired}</p>`;
  },
  //Cases List
  casesListLabel: "Customer Support",
  casesListItensCase: "Case",
  casesListItensMachine: "BuyBye",
  casesListItensStatus: "Status",
  casesListItensCreatedAt: "Created At",
  casesListNoItens: "You don't have any cases.",
  casesListItensStatusTrad: {
    Open: "Open",
    Closed: "Closed",
    "In Resolution": "In Resolution",
  },
  // help form
  helpForm: {
    title: "Customer Feedback",
    mentionId: "Machine or Invoice ID",
    subject: "Subject",
    description: { label: "Description", placeholder: "Enter your message" },
    contactMethod: {
      lable: "Contact Method",
      phone: "Mobile Phone",
      email: "Email",
    },
    email: "Email",
    phone: "Mobile Phone",
  },
  // verify
  verify: { title: "Validation Code" },
  //caseManagement
  caseManagement: {
    title: "Case Management",
    subtitle: {
      title: "Talk with us.",
      subtitle:
        "Fill in the form and you will be contacted by our support team.",
    },
  },
  // actions
  action: {
    sendMessage: "Enviar mensagem",
    setNewPassword: "Set New Password",
    getCode: "Get Code",
  },
  //error
  error: {
    requiredFields: "Fill in all required fields.",
    validData: "Please fill in the fields with reliable information.",
  },
  //forgot Password
  forgot: {
    phoneNumber: "Insert your phone number.",
    helpTip: "We will send a message with validation code.",
  },
  // global
  global: {
    email: "Email",
    name: "Name",
    phone: "Mobile Phone",
    username: "Username",
    acceptTerms: "* By clicking your are accepting",
    signInTip: "Already have an account! Login",
    vat: "VAT ID",
    logout: "Logout",
    pt: " Portuguese",
    en: " English",
    language: "Language",
    password: "Password",
    newPassword: "New Password",
    signIn: "Login",
    forgotPassword: "Forgot Password?",
    signUp: "Don't have an account? Sign Up",
    terms: "Terms and Conditions ",
  },
  //inShop
  inShop: {
    title: "Shop",
    text: {
      p1: "Pick the products you want.",
      p2: "Keep the products in the right place.",
      p3: "Close the doors to finish your shop.",
    },
  },
  //payments
  payments: {
    title: "Payment Cards",
    noCards: "Register your 1st Card.",
    expirationDate: "Expiration Date",
    modalTitle: "New Card Registration",
    epg: {
      body: `To guarantee the veracity of the card we will capture 1 AED on card
      registration. Don't worry, this amount will be returned after the
      operation. 
      <br></br>All card details are protected by 3D secure transaction
      provided by Etisalat&reg;.`,
    },
    stripe: {
      body: `To guarantee the good use of your card you will capture 1 € on the card
      registration. Don't worry, this amount will be returned after an operation.
      <br> </br> All card details are protected by Stripe&reg;.`,
    },
    stripe3DS: {
      body: `To guarantee the good use of your card you will capture 1 € on the card
      registration. Don't worry, this amount will be returned after an operation.
      <br> </br> All card details are protected by Stripe&reg;.`,
    },
  },
  // profile form
  profile: { title: "Profile" },
  // debt form
  debt: {
    title: "Ups. It looks like you have a pending debt.",
    body: (values) => {
      if (!values) {
        values = { total: "", currency: "" };
      }
      return `To continue shopping at Smart Fridge Ponto you have to pay the missing amount ${values.currency} ${values.total} (refers to ${values.debts} receipts). By clicking continue, you are authorizing the missing payment.`;
    },
    cancel: "Cancel",
    continue: "Continue",
  },
};
